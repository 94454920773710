export const { REACT_APP_BASE_URL } = process.env;

export const API_ENDPOINTS = {
    // Pages
    SALES: '/sales',
    ORDER_BOOK: '/orderBook',
    DESPATCH: '/despatch',
    HISTORY: '/history',

    // CRUD Stuff
    CREATE_ORDER: '/createOrder',
    MODIFY_ORDER: '/orders',

    MODIFY_PRODUCT: '/modifyProduct',
    CREATE_CLIENT: '/createClient',
    ADD_COMMENT: '/addComment',
    PRINT_LABELS: '/printLabels',
    CREATE_INVOICE: '/createInvoice',
    LOGIN: '/login',
    LOGOUT: '/logout',

    // Lookup stuff
    GET_CLIENTS: '/clients',
    GET_MODELS: '/models',
    GET_FRAMES: '/frames',
    GET_COLOURS: '/colours',
    GET_USERS: '/users',
    GET_PCFORMS: '/getPCForms',

    GET_PARTS_DASHBOARD: '/partsInfo',

    GET_DASHBOARD_SALES: '/saleSection',
    GET_DASHBOARD_ACCOUNTS: '/getSalesQuotas',

    GET_TIMESHEETS: '/clockOutInfo',

    GET_DASHBOARD_PRODUCTIONS: '/onTimeOrders',
    GET_DASHBOARD_CURR_ORDERS: '/currentOrders',

    GET_WHOLESALER_WEEKS: '/settings',
    MODIFY_WHOLESALER_WEEKS: '/settings',
    // Table Actions
    // Get
    CHECK_PAID: '/checkPaid',
    SEND_EMAIL: '/sendXeroEmail',
    FETCH_LOOKUP: '/getProductData',

    ORDERS: '/orders',

    // Parts
    FETCH_PARTS: '/getParts',
    ADD_PART: '/addPart',
    MODIFY_PART: '/modifyPart',
    MODIFY_MODEL_PARTS: '/modifyModelParts',

    // Put
    UPDATE_FINISHED_BY: '/submitEmpNumber',
    MODIFY_MODEL: '/models',
    MODIFY_COLOUR: '/colours',
    MODIFY_FRAME: '/frames',
    MODIFY_USER: '/users', // users/{id}
    MODIFY_CLIENT: '/modifyClient',
    MODIFY_PCFORM: '/modifyPCForm',

    // Post
    ADD_CONNOTE: '/addConnote',
    FINISH_ORDER: '/finishOrder',
    CREATE_MODEL: '/models',
    CREATE_COLOUR: '/colours',
    CREATE_FRAME: '/frames',
    CREATE_USER: '/users',
    CREATE_PCFORM: '/createPCForm',

    // Employees
    GET_EMPLOYEES: '/employees',
    CREATE_EMPLOYEE: '/employees',
    MODIFY_EMPLOYEE: '/employees',

    DELETE_ORDER: '/deleteOrder',

    GET_DAYWORK: '/getDayWork',
    // image endpoints
    // /images/{orderNo}
    UPLOAD_IMG: '/images',

    // images/{orderNo}
    GET_IMG: '/images',

    // /images/{orderNo}/{fileName}
    DELETE_IMG: '/images',

    // Announcements
    GET_ANNOUNCE: '/announcements',
    // {?id=x} for modification
    POST_ANNOUNCE: '/announcements',
    // {?id=x} for delete
    DELETE_ANNOUNCE: '/announcements',

    // Send Back
    // {?page=orderbook/despatch}
    POST_SEND_BACK: '/sendBack',

    // Force update opto
    OPTO_UPDATE: '/optoupdate',

    // Quotas
    GET_QUOTA: '/quotas/getQuota',
    SET_QUOTA: '/quotas/setQuota',
    SET_FINISHED: '/quotas/setFinishedOrders',

    // Privacy -- POST
    PRIVACY_NOTICE: '/privacy'
};
