import { API } from "api"
import { API_ENDPOINTS } from "util/constants/api"

export const setFinishedOrders = async (finished) => {
    return await API.post(`${API_ENDPOINTS.SET_FINISHED}?finished=${finished}`)
    .then(({ data }) => {
        return {
            success: true,
            data   
        }
    }).catch((error) => {
        return {
            success: false,
            error
        }
    })
}

export const setQuota = async (quota) => {
    return await API.post(`${API_ENDPOINTS.SET_QUOTA}?quota=${quota}`)
    .then(({ data }) => {
        return {
            success: true,
            data   
        }
    }).catch((error) => {
        return {
            success: false,
            error
        }
    })
}

export const acceptPrivacyNotice = async (isAccept) => {
    return await API.post(API_ENDPOINTS.PRIVACY_NOTICE)
    .then(({ data }) => {
        return {
            success: true,
            data
        }
    }).catch((error) => {
        return {
            success: false,
            error
        }
    })
}