import { useState } from 'react'
import { Link } from "react-router-dom";
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Button, MenuItem, Paper, Icon, Stack } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { Logout, SettingsOutlined } from '@mui/icons-material';
import { useLogout } from 'hooks/auth.hooks';
import { PermissionBarrier } from 'components/Permissions';
import { ROLES } from 'util/constants/permissions';
import logo from 'assets/logo-revolvalshade-white.png'

const pages = [
	"Sales",
	"Orderbook",
	"Despatch",
    "History"
];

const Header = () => {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const { doLogout } = useLogout()

    //Mobile compatibility
    const handleOpenNavMenu = (event) => {
            setAnchorElNav(event.currentTarget);
    }

    const handleCloseNavMenu = (event) => {
            setAnchorElNav(null);
    }

    return (
        <AppBar
            position="static"
            elevation={0}
            sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        >
            <Paper>
                <Container maxWidth="xl" >
                    <Toolbar disableGutters sx={{justifyContent: 'space-between'}}>
                        <Box variant="h6" component={Link} to={'/'} color="inherit" sx={{ flexGrow: 0.85, textDecoration: 'none', alignItems: 'center', display: 'flex' }}>
                            <img src={logo} alt="logo" height={65}/>
                        </Box>
                        <nav>
                            <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', justifyContent: 'flex-end' }}>
                                {pages.map((page) => (
                                    <Button
                                        key={page}
                                        sx={{ my: 2, color: 'white', display: 'block' }}
                                        component={Link} to={page.toLowerCase()}>
                                        {page}
                                    </Button>
                                ))}
                                <PermissionBarrier scopes={[ROLES.admin, ROLES.sales]}>
                                    <IconButton size="large" component={Link} to={'settings'}>
                                        <SettingsOutlined />
                                    </IconButton>
                                </PermissionBarrier>
                                <PermissionBarrier scopes={[ROLES.wholesaler, ROLES.factory]}>
                                    <Button size="large" onClick={doLogout} color="error" endIcon={<Logout />}>
                                        Logout
                                    </Button>
                                </PermissionBarrier>
                            </Box>
                            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="navigation of the webpage"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                >
                                    {pages.map((page) => (
                                        <MenuItem component={Link} to={page.toLowerCase()} key={page} onClick={handleCloseNavMenu}>
                                            <Typography textAlign="center">{page}</Typography>
                                        </MenuItem>
                                    ))}
                                    <MenuItem onClick={doLogout}>
                                        <Stack direction={'row'} alignContent={'center'}>
                                            <Typography color="error">
                                                Logout
                                            </Typography>

                                            <Logout color="error" />
                                        </Stack>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </nav>
                    </Toolbar>
                </Container>
            </Paper>
        </AppBar>
    )
}

export default Header